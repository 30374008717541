<div>
  <h1 class="font-bold text-gray-500 mb-2">
    <i class="el-icon-document"></i> Danh sách đơn vị cung cấp Hoá đơn điện tử
  </h1>
  <el-scrollbar
    class="scrollbar shadow-xl xl:w-10/12 m-auto"
    v-loading="isLoading"
  >
    <el-table
      :data="digitalInvoiceProviders"
      border
      style="width: 100%"
      empty-text="Không có dữ liệu"
      :header-cell-style="{ background: 'white' }"
      ref="tableData"
      @row-click="clickRow($event)"
      :row-class-name="handleRowSupported"
    >
      <el-table-column type="index" width="70" label="STT" align="center" />

      <el-table-column
        prop="msttcgp"
        label="Tên công ty hoá đơn điện tử"
        min-width="200"
        align="left"
      >
        <template #default="scope">
          {{ transformTaxCodeToText(scope.row.msttcgp) }}
        </template>
      </el-table-column>

      <el-table-column
        prop="msttcgp"
        label="Mã số thuế"
        width="150"
        align="center"
      />

      <el-table-column
        prop="msttcgp_count"
        label="Công ty sử dụng"
        width="200"
        align="center"
      >
        <template #default="scope">
          {{ formatNumber(scope.row.msttcgp_count) }}
        </template>
      </el-table-column>

      <el-table-column width="100" align="center">
        <template #header>Chi tiết</template>
        <template #default="scope">
          <i class="el-icon-right" />
        </template>
      </el-table-column>
    </el-table>
  </el-scrollbar>
</div>

<el-dialog
  top="2vh"
  v-model="showDetail"
  width="80%"
  @closed="showDetail=false"
>
  <div class="flex justify-between mb-2">
    <h1 class="font-bold text-gray-500">
      {{ transformTaxCodeToText(msttcgpSelected) }} - {{ msttcgpSelected }}
    </h1>

    <SearchInput
      v-model="search"
      class="w-96"
      :isIcon="true"
      placeholder="Tìm kiếm theo tên doanh nghiệp hoặc mã số thuế"
      @inputSearch="search = $event"
      @doneTyping="getDigitalInvoiceProvidersDetail()"
    />

  </div>

  <el-scrollbar
    class="scrollbar-75 shadow-xl xl:w-10/12 m-auto"
    v-loading="isLoading"
  >
    <el-table
      :data="digitalInvoiceProvidersDetail"
      border
      stripe
      style="width: 100%"
      empty-text="Không có dữ liệu"
    >
      <el-table-column
        type="index"
        width="70"
        :index="indexMethod"
        label="STT"
        align="center"
      />
      <el-table-column
        prop="tenDoanhNghiep"
        label="Tên công ty sử dụng hoá đơn điện tử"
        min-width="200"
        align="left"
      />
      <el-table-column
        prop="mst"
        label="Mã số thuế"
        width="150"
        align="center"
      />
    </el-table>
  </el-scrollbar>

  <Paginate
    :currentPage="paginate.currentPage"
    :totalItem="paginate.totalItem"
    :totalPage="paginate.totalPage"
    :limit="paginate.limit"
    @modifiedCurrentPage="handleCurrentPageChange($event)"
    @update:limit="handleLimitUpdate"
  />
</el-dialog>
