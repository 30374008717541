import { URL } from "@/api/dashBoard.js";
import { URL as COMPANY_URL } from "@/api/company";
import formatNumber from "@/plugins/formatNumber.js";
import { MSTTCGP_TEXT } from "../../../../consts";
import Paginate from "@/components/paginate.vue";
import SearchInput from "@/components/searchInput";

export default {
  data() {
    return {
      isLoading: false,
      showDetail: false,
      msttcgpSelected: null,
      digitalInvoiceProviders: [],
      digitalInvoiceProvidersDetail: [],
      search: null,
      paginate: {
        limit: 20,
        currentPage: 1,
        cursor: null,
        next: null,
        prev: null,
      },
      msttcgp_supported: []
    };
  },
  components: {
    Paginate,
    SearchInput
  },
  created() {
    this.getDigitalInvoiceProviders();
  },
  methods: {
    async getDigitalInvoiceProviders() {
      const result = await this.$request({
        url: URL.SYSTEM_STATISTIC_DIGITAL_INVOICE_PROVIDERS,
      });

      const res = result.data;
      if (res.code == 200) {
        this.digitalInvoiceProviders = res.data.data;
        this.msttcgp_supported = res.data.msttcgp_supported;
      }
    },

    async getDigitalInvoiceProvidersDetail() {
      const params = {
        msttcgp: this.msttcgpSelected,
        page: this.paginate.currentPage,
        limit: this.paginate.limit,
      };

      if (this.search) params.key_search = this.search;

      this.isLoading = true;
      const result = await this.$request({
        url: COMPANY_URL.LIST_COMPANY,
        params,
      });
      this.isLoading = false;

      const res = result.data;
      if (res.code == 200) {
        this.digitalInvoiceProvidersDetail = res.data.data;

        this.paginate.totalPage = res.data.total_pages;
        this.paginate.totalItem = res.data.total;
        this.paginate.limit = res.data.limit;
      }
    },

    transformTaxCodeToText(mst) {
      if (Object.keys(MSTTCGP_TEXT).indexOf(mst) != -1) {
        return MSTTCGP_TEXT[mst];
      }

      return mst;
    },
    async clickRow(e) {
      this.showDetail = true;
      this.msttcgpSelected = e.msttcgp;
      await this.getDigitalInvoiceProvidersDetail();
    },
    handleCurrentPageChange(e) {
      this.paginate.currentPage = e;
      this.getDigitalInvoiceProvidersDetail();
    },
    handleLimitUpdate(e) {
      this.paginate.limit = e;
      this.getDigitalInvoiceProvidersDetail();
    },
    indexMethod(index) {
      return index + 1 + (this.paginate.currentPage - 1) * this.paginate.limit;
    },
    handleRowSupported ({row}) {
      if (this.msttcgp_supported.indexOf(row.msttcgp) != -1) {
        return "success-row";
      }
      return '';
    },
    formatNumber,
  },
};
